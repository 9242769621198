@import '../../../../sass/variables.scss';

.skills {
  width: 100%;
  padding: 5rem 2rem 5rem 2rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 5rem 0;
  }

  &-header {
    margin: 0rem 0rem 2rem 0;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $bp-small) {
      justify-content: center;
    }

    &-icon {
      margin: 1rem;
      color: $color-blue-dark;
      height: 4rem;
      width: 4rem;
    }
  }
  &-icon {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.html {
  color: $color-html;
}
.css {
  color: $color-css;
}
.js {
  color: $color-js;
}
.ts {
  color: $color-ts;
}
.react {
  color: $color-react;
}
.sql {
}
.sap {
  color: $color-sap;
}
.exl {
  color: $color-green-dark;
}
.git {
  color: $color-git;
}
.sass {
  color: $color-sass;
}
.node {
  color: $color-node;
}
