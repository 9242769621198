@import "../../../../sass/variables.scss";

.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 2rem 0;
  position: relative;

  &::after {
    content: "";
    display: block;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: $bp-medium) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &_item {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    width: 100%;

    &--icon {
      height: 3rem;
      width: 3rem;
      margin: 1rem;
    }
  }
}
.phone {
  cursor: pointer;
}
.email {
  color: inherit;
}
