@import '../../../../sass/variables.scss';

.project-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: auto;
  padding: 2rem 4rem;
  position: relative;

  @media only screen and (max-width: $bp-small) {
    padding: 1rem;
  }

  //scrollbar
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light-3;
    &:hover {
      background: $color-grey-dark-1;
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 2;

    margin: 3rem 0;
    width: 100%;

    &-header {
      display: flex;
      width: 100%;
      margin-bottom: 3rem;

      @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
      }
      &--title {
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_subtitle {
          margin: 0;
          text-align: center;
          font-size: 2.5rem;
        }
      }
    }
    &-overview {
      font-size: 2rem;
      margin: 2rem 1rem;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $bp-medium) {
        justify-content: center;
        align-items: center;
      }
      &:first-child {
        margin-top: 12rem;
      }

      &_info {
        margin: 2rem 0;
        &--pharagraph {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          text-align: justify;
          margin: 1rem 1rem;
        }
      }
      &--link {
        margin: 1rem;
      }
    }
    &-gitbtn {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0rem;
      width: 100%;
      font-size: 1.2rem;
      letter-spacing: 0.05rem;
      position: relative;

      &:before {
        content: '';
        display: block;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #fff;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 0;
      }

      &--logo {
        height: 4rem;
        width: auto;
        margin: 0;
        padding: 0;

        overflow: hidden;
      }
      &--code {
        span {
          color: $color-blue-light-2;
          font-weight: 700;
        }
      }
    }
  }
}

.picture-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 2rem 0rem;
  flex-wrap: wrap;
  position: relative;

  @media only screen and (max-width: $bp-medium) {
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
  }

  &--img1 {
    flex-basis: 50%;
    position: relative;
    animation: scale ease-out 1s;

    @media only screen and (max-width: $bp-medium) {
      flex-basis: 0;
      width: 90%;
    }
  }

  &--img2 {
    flex-basis: 30%;
    position: relative;
    animation: scale ease-out 1s;

    @media only screen and (max-width: $bp-medium) {
      position: absolute;
      top: 35%;
      right: 5%;
      height: 60%;
    }
  }
}

.website_img_container {
}

.images {
  &-img1 {
    width: 100%;
    height: auto;
    max-width: 100%;
    z-index: 2;
    transform: rotate(-3deg);
    border: 5px solid $color-white;
    border-radius: 10px;
    box-shadow: var(--shadow-light);
  }
  &-img2 {
    width: auto;
    height: 100%;
    max-width: 100%;
    transform: rotate(5deg);
    z-index: 2;

    @media only screen and (max-width: $bp-medium) {
      transform: rotate(10deg);
    }
  }

  &-website {
    height: 30rem;
    width: 30rem;
  }
}
