@import "../../sass/animations";
@import "../../sass/variables.scss";

.card {
  height: 85dvh;
  flex-basis: 55%;
  background-color: $color-grey-light-1;
  position: relative;
  padding-right: 5px;

  &::before {
    content: "";
    top: 0;
    left: 0;
    height: 6rem;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 3;

    background-image: linear-gradient(
      to bottom,
      $color-grey-light-1 40%,
      transparent 100%
    );
  }

  &::after {
    content: "";
    bottom: 0;
    left: 0;
    height: 6rem;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 3;

    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      $color-grey-light-1 60%
    );
  }

  @media only screen and (max-width: $bp-largest) {
    flex-basis: 60%;
  }

  @media only screen and (max-width: $bp-medium-low) {
    height: 100dvh;
    flex-basis: 85%;
  }

  @media only screen and (max-width: $bp-smallest) {
    flex-basis: 90%;
    width: 100%;
  }
  &--active {
    animation: moveInLeft 1s ease-in-out backwards;
    will-change: transform;
    &-start {
      animation: moveInLeftStart 1s ease-in-out backwards;
      will-change: transform;
    }
  }
}
