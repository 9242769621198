@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $color-black;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: $bp-large) {
    font-size: 60%;
  }

  @media only screen and (max-width: $bp-small) {
    font-size: 55%;
  }

  @media only screen and (max-width: $bp-smallest) {
    font-size: 50%;
  }
}

body {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;

  background-image: $background;

  background-size: cover;
}
