@import '../../../sass/variables.scss';
@import '../../../sass/animations';

.about_me {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: auto;
  height: 100%;

  overflow: auto;
  padding: 2rem 4rem;

  @media only screen and (max-width: $bp-small) {
    padding: 2rem;
  }

  //scrollbar
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light-3;
    &:hover {
      background: $color-grey-dark-1;
    }
  }
}

.subtitle {
  font-size: 2rem;
  padding: 3rem 0 0 0;

  span {
    color: $color-blue-light-2;
    font-size: 2.5rem;
  }

  @media only screen and (max-width: $bp-small) {
    text-align: center;
  }
}

.info {
  font-size: 1.6rem;
  padding: 2rem 0;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid $color-white;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
  }
}

.header {
  @media only screen and (max-width: $bp-small) {
  }
}
