@import "../sass/variables.scss";
@import "../sass/animations";

.container {
  height: 95dvh;
  width: 75%;

  margin: 2rem auto;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: $bp-largest) {
    width: 85%;
  }
  @media only screen and (max-width: $bp-large) {
    width: 95%;
  }

  @media only screen and (max-width: $bp-medium-low) {
    height: 100dvh;
    width: 95%;
    margin: auto;
  }

  @media only screen and (max-width: $bp-small) {
    width: 100%;
    margin: 0;
    justify-content: flex-start;
  }
}

//headers
h1 {
  font-family: "Coolvetica", "Xball", sans-serif;
  font-size: 6rem;
  font-weight: 400;
  position: relative;

  span {
    color: rgba(99, 178, 255, 0.75);
    position: relative;
    padding-right: 2rem;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      width: 1.5rem;
      height: 5rem;
      background: rgba(99, 178, 255, 0.75);
      animation: blink 1s ease-in-out 1s infinite normal none running;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 8rem;
    height: 5px;
    background: $color-blue;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #fff;
    margin-top: 0rem;
  }

  @media only screen and (max-width: $bp-small) {
    font-size: 5rem;
  }
}

h2 {
  font-family: "Coolvetica", sans-serif;

  font-size: 4rem;
  font-weight: 400;
  position: relative;
  margin: 2rem 0rem 2rem 2rem;
  span {
    color: rgba(99, 178, 255, 0.75);
    position: relative;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 4rem;
    height: 3px;
    background: $color-blue;
    margin-top: 0rem;
  }
}

h3 {
  font-family: "Coolvetica", sans-serif;

  font-size: 3rem;
  font-weight: 400;
  position: relative;
  margin: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: rgba(99, 178, 255, 0.75);
  }
}
h4 {
  font-family: "Coolvetica", sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  position: relative;
  margin: 0.5rem 0;

  flex-wrap: wrap;

  span {
    color: rgba(99, 178, 255, 0.8);
  }
}
p {
  font-size: 1.6rem;
  text-align: justify;
  font-family: Helvetica, sans-serif;
}
