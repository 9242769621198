@import "../../sass/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5rem;
  text-align: center;
  width: 20rem;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  color: $color-white;
  font-weight: bold;
  letter-spacing: 0.05rem;

  &:hover {
    color: $color-blue-dark;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($color-blue-dark, 0.7);
    transition: all 0.3s;
  }
  &:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba($color-blue-dark, 0.5);
    transform: scale(1.2, 1.2);
  }
  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
}
