@import '../../sass/variables.scss';

.btn {
  font-size: 1.7rem;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(
    to right,
    var(--color-primary-light-2),
    10%,
    var(--color-primary-light),
    70%,
    var(--color-primary-dark)
  );
  color: $color-white;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 1rem;
  z-index: 10;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);

  & > * {
    display: inline-block;
    height: 100%;
    width: 100%;
    transition: all ease-in-out 0.2s;
  }

  @media only screen and (max-width: $bp-small) {
    font-size: 2rem;
  }

  &__visible {
    padding: 1rem 3rem;
  }

  &__invisible {
    position: absolute;
    padding: 0.5rem 0;
    left: 0;
    top: -100%;
    &--icon {
      height: 3rem;
      width: auto;
    }
  }

  &:hover {
    background-image: linear-gradient(
      to left bottom,
      var(--color-primary-light-2),
      10%,
      var(--color-primary-light),
      70%,
      var(--color-primary-dark)
    );
  }

  &:hover &__visible {
    transform: translateY(100%);
  }

  &:hover &__invisible {
    top: 0;
  }

  &:focus {
    animation: 0.5s pulsate;
  }
}
