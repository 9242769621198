@import '../../../../sass/variables.scss';

.component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1.6rem;
  margin: 2rem 0;

  &-title {
    font-size: 2rem;
    margin: 0.5rem 0;
  }
  &-place {
    margin: -0.5rem 0 0.5rem 0;
  }
  &-date {
    color: $color-blue;
    font-weight: bolder;
  }
  &-city {
    font-size: 1.4rem;
    color: $color-grey-light-4;
  }
  &-info {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}
