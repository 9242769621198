@import "../../../../sass/variables.scss";
@import "../../../../sass/animations";

.hobbies {
  display: flex;
  flex-flow: column wrap;
  font-size: 1.6rem;
  padding: 2rem 0;

  @media only screen and (max-width: $bp-small) {
    align-items: center;
  }

  &-block {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

    margin-bottom: 1rem;
    @media only screen and (max-width: $bp-small) {
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
  }

  &-icon {
    height: 4rem;
    width: 4rem;
    margin: 1.5rem;
  }

  &-icons {
    z-index: 3;
    transition: 0.3s all;

    &:hover {
      transform: scale(1.3) rotate(15deg);
      opacity: 1;
    }

    &:not(:hover) .hobbies-icons {
      opacity: 0;
    }

    &:hover + .hobbies-name {
      opacity: 1;
      animation: moveFromTop 1s;
    }
  }

  &-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-weight: bold;
  }
}
