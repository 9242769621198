@import '../../../../sass/variables.scss';

.project-block {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;

  @media only screen and (max-width: $bp-medium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
