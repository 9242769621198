@import '../../../../sass/variables.scss';
@import '../../../../sass/animations';

.skill-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  margin: 0 2rem;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--icon {
    z-index: 1;
    overflow: hidden;
    margin: 1rem;
    transition: 0.2s all;

    &:hover {
      transform: scale(1.2) rotate(15deg);
      opacity: 1;
    }

    &:hover + .skill--name {
      opacity: 1;
      animation: moveFromTop 1s;
    }
  }
  &--name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 1rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
