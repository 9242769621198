// Variables
$color: #c0392b;
$color-dark: #a63326;
$transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);

/* Main Styles */
.buttonGit {
  display: flex;
  background-color: $color;
  width: 18rem;
  height: 6rem;
  line-height: 6rem;
  font-family: 'Coolvetica', sans-serif;
  letter-spacing: 3px;
  color: #fff;
  position: relative;

  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  transition: $transition;
  margin: 1rem;

  span,
  .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }

  span {
    width: 72%;
    line-height: inherit;
    font-size: 2rem;
    text-transform: uppercase;
    left: 0;
    transition: $transition;

    &:after {
      content: '';
      background-color: $color-dark;
      width: 2px;
      height: 70%;
      position: absolute;
      top: 15%;
      right: -1px;
    }
  }

  .icon {
    width: 28%;
    right: 0;
    transition: $transition;

    &-link {
      font-size: 3rem;
      vertical-align: middle;
      transition: $transition, height 0.25s ease;
      height: 36px;
    }
  }

  &:hover {
    span {
      left: -72%;
      opacity: 0;
    }

    .icon {
      width: 100%;

      &-link {
        font-size: 4.5rem;
      }
    }
  }

  &:hover {
    opacity: 0.9;

    .icon .icon-link {
      height: 4.6rem;
    }
  }

  &:active {
    opacity: 1;
  }
}
