//colors
$color-red: #a80b0b;

$color-green-light: #7ed56f;
$color-green-dark: #18b581;

$color-yelow-dark: #ffb900;
$color-yelow-light: rgb(255, 204, 0);

$color-blue: #439bfa;
$color-blue-light: #5aafff;
$color-blue-light-2: #a9d2e7;
$color-blue-dark: #236aa1;
// $color-blue-dark: #094d9fd2;

$color-grey-light-1: #fcfcfc;
$color-grey-light-2: #f2eeee;
$color-grey-light-3: #ccc;
$color-grey-light-4: #b6b6b6;
$color-grey-dark-1: #999;
$color-grey-dark-2: #777;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$background: linear-gradient(to right bottom, $color-white, $color-blue-light);

$color-sap: #0054a3;
$color-html: #ff7b00;
$color-css: #264de4;
$color-git: #c83900;
$color-sass: #e95a8c;
$color-node: #339c20;
$color-js: #f7df1e;
$color-ts: #197be3;
$color-react: #71d0ff;
$color-ln: #2867b2;

//profile icons
$dark: $color-blue-dark;
$dark-download: var(--color-primary);
$light: $color-white;

//screen break points
$bp-largest: 93.75em;
$bp-large: 75em;
$bp-medium: 62em;
$bp-medium-low: 44em;
$bp-small: 36em;
$bp-smallest: 20.625em;

$bp-name-largest: 106.975em;
$bp-name-large: 82.5em;
$bp-name-medium: 53.875em;
$bp-download-btn-transfer: 50em;

:root {
  --color-primary: #d46f1d;
  --color-primary-light: #f8941a;
  --color-primary-light-2: #ffd998;
  --color-primary-light-3: #fceabb;
  --color-primary-light-4: rgb(255, 247, 226);
  --color-primary-dark: #b74c00;

  --shadow-dark-btn: 0.2rem 0.3rem 1rem rgba(0, 0, 0, 0.5);
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
}
