@import '../../../../sass/variables.scss';

.personality {
  flex-wrap: wrap;
  margin: 2rem 1rem;

  &-traits {
    width: 100%;
    display: grid;
    gap: 1rem 3rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    @media only screen and (min-width: $bp-smallest) and (max-width: 59em) {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  }

  &-skill {
    display: flex;
    align-items: center;
    transition: 0.1s all;

    &:hover .personality-icon {
      transform: scale(1.35);
      color: $color-blue-dark;
    }
  }

  &-trait {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    &:hover .personality-icon {
      transform: scale(1.3);
      color: $color-blue-dark;
    }
  }

  &-icon {
    color: $color-blue;
    margin: 1rem;
    height: 3rem;
    width: 3rem;

    &:hover {
      transform: scale(1.3);
      color: $color-blue-dark;
    }
  }
}
