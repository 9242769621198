@import '../../../sass/variables.scss';

.exp-edu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: auto;
  height: 100%;

  overflow: auto;
  padding: 2rem 2rem;

  //scrollbar
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light-3;
    &:hover {
      background: $color-grey-dark-1;
    }
  }

  &--info {
    padding: 1rem 1rem 5rem 2rem;
    position: relative;

    &::after {
      content: '';
      display: block;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media only screen and (max-width: $bp-small) {
      padding: 1rem 0rem 5rem 0rem;
    }

    &_subtitle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      & span {
        padding: 0 0.5rem;
        font-size: 3rem;
      }
    }
  }

  &--container {
    width: 100%;
    display: flex;
    position: relative;

    &::after {
      content: '';
      display: block;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
    }

    &_block {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      padding: 0 2rem;
      flex: 1;

      @media only screen and (max-width: $bp-medium) {
        justify-content: center;
        align-items: center;
        text-align: center;

        padding: 0;
      }

      &-header {
        display: flex;
        align-items: center;
        margin: 2rem 0;
        &--icon {
          color: $color-blue-dark;
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }
}
