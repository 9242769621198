@import '../../../sass/variables.scss';

.projects {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: auto;
  height: 100%;

  overflow: auto;
  padding: 2rem 4rem;

  //scrollbar
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light-3;
    &:hover {
      background: $color-grey-dark-1;
    }
  }

  &-container {
    margin: 2rem 0;
    transition: all 0.2s ease-in-out;
    position: relative;

    &::after {
      content: '';
      display: block;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        height: 4rem;
        width: auto;
        margin: 1rem;
      }
    }
  }
}

.icons {
  &-react {
    animation: rotate 5s infinite linear;
    color: $color-react;
  }
  &-javaScript {
    color: $color-js;
    animation: fadeInFadeOut 3s infinite linear;
  }
}
