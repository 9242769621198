@import '../../../../sass/variables.scss';

.project-component {
  flex: 0 0 48%;
  margin: 4rem 0 7rem 0;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;

  @media only screen and (max-width: $bp-medium) {
    width: 80%;
  }
  @media only screen and (max-width: $bp-small) {
    margin: 1rem 0 7rem 0;
    width: 90%;
  }

  &:hover &-info_container {
    background: rgba(255, 255, 255, 0.9);
  }

  &:hover &-picture--img {
    transform: translateY(-1.25rem);
  }

  &:hover &-info--title {
    color: $color-blue;

    span {
      color: var(--color-primary-light);
    }
  }

  &-picture {
    display: flex;
    align-items: center;
    justify-content: center;

    &--img {
      height: 20rem;
      width: 80%;
      object-fit: contain;
    }
  }

  &-info_container {
    position: absolute;
    max-height: 70%;
    min-height: 60%;
    width: 100%;
    bottom: -3rem;
    left: 50%;
    transform: translate(-50%);
    background: rgba(255, 255, 255, 0.95);
    padding: 0.5rem 2rem;
    box-shadow: var(--shadow-light);
  }
  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $color-black;

    &--title {
      color: $color-black;
      margin-bottom: 0;
    }
    &--subtitle {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    &--tags {
      font-size: 1.3rem;
      color: $color-grey-dark-1;
      padding-bottom: 1rem;
    }
  }
}
