@import '../../../sass/animations';
@import '../../../sass/variables.scss';

.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: auto;
  padding: 2rem 4rem;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: $bp-small) {
    padding: 2rem;
  }

  //scrollbar
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light-3;
    &:hover {
      background: $color-grey-dark-1;
    }
  }

  &-message {
    font-size: 1.6rem;
    padding: 3rem 0;
    text-align: center;
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
