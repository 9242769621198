@import '../../../../sass/variables.scss';

.contact-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

  &--links {
    text-decoration: none;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--icon {
    display: inline-block;
    height: 5rem;
    width: 5rem;
    margin: 2rem 2rem;

    &_ln {
      color: $color-ln;
    }
    &_git {
      color: $color-black;
    }
    &_ig {
      border-radius: 10px;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
      color: #fff;
    }
  }
}
