@import '../../../../sass/variables.scss';

.languages {
  margin: 3rem 1rem;

  @media only screen and (max-width: $bp-medium) {
    text-align: center;
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      justify-content: center;
    }
    &-icon {
      margin-right: 1rem;
      color: var(--color-primary-dark);
    }
  }
  &-container {
    list-style: none;
    margin: 2rem;
    &-info {
      font-size: 1.7rem;
      span {
        font-weight: bold;
      }
    }
  }
}
