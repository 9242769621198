@import '../../sass/animations';
@import '../../sass/variables.scss';

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
  height: 20rem;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 20rem;
  font-family: sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: $color-white;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px $color-white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid $color-white;
    border-right: 3px solid $color-white;
    border-radius: 50%;
    animation: animateRing1 2s linear infinite;
  }
}

.load {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animateRing2 2s linear infinite;

  &:before {
    content: '';
    position: absolute;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: $color-white;
    top: -8px;
    right: -10px;
    box-shadow: 0 0 20px $color-white;
  }
}
