@import '../../sass/variables.scss';

.side-nav {
  &__item {
    position: relative;
    cursor: pointer;

    &--download {
      display: none;

      &::before {
        display: none;
      }

      @media only screen and (max-width: $bp-download-btn-transfer) {
        display: block;
      }
    }
  }

  &__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: radial-gradient(
      circle farthest-side,
      var(--color-primary-light-3),
      var(--color-primary)
    );
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
      background-color 0.1s;
  }

  &__item:hover::before,
  &__item--active::before {
    transform: scaleY(1);
    width: 100%;
  }

  &__item:active::before {
    background: radial-gradient(
      circle farthest-side,
      var(--color-primary-light-4),
      var(--color-primary)
    );
  }

  &__link--icon {
    height: 3rem;
    width: 3rem;

    &--download {
      height: 3.5rem;
      width: auto;
      color: var(--color-primary);

      &:active,
      &:focus,
      &:target,
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &__link:link,
  &__link:visited {
    text-decoration: none;
    text-transform: uppercase;
    padding: 1.5rem 2rem;
    position: relative;
    z-index: 10;
    color: $color-black;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link--download:link,
  &__link--download:visited {
    // padding: 1.2rem 0rem;
  }
}
