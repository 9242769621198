@import "../../sass/variables.scss";

.sidebar {
  height: 85dvh;
  flex-basis: 5%;
  background-color: $color-grey-light-2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 200;

  @media only screen and (max-width: $bp-medium-low) {
    height: 100dvh;
    flex-basis: 15%;

    &::after {
      content: "";
      display: block;
      border-right: 1px solid #ddd;
      border-left: 1px solid $color-grey-light-1;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media only screen and (max-width: $bp-smallest) {
    flex-basis: 10%;
  }

  &-logo {
    overflow: hidden;
    padding: 0.8rem;
    height: auto;
    width: auto;
  }
}

.side-nav {
  list-style: none;
}
.footer {
  margin: 4rem 0.5rem 0.5rem;
  color: $color-black;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
}

//custom made experience-education icon
.custom-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $color-black;
  max-height: fit-content;
  max-width: fit-content;
}

.briefcase-icon {
  z-index: 0;
  clip-path: polygon(0% 0%, 0% 100%, 45% 100%, 60% 50%, 100% 50%, 100% 0%);
}

.graduation-icon {
  z-index: 3;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: -0.7rem;
  right: -0.9rem;
  transform: rotate(15deg);
}
