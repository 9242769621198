@import '../../sass/variables.scss';

.profile {
  height: 100%;
  flex-basis: 40%;
  background: linear-gradient(0.25turn, #3e92cc, #fff, #3e92cc);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0 0rem 3rem 1.8rem rgba($color-black, 0.45);
  overflow: hidden;
  z-index: 200;
  position: relative;

  &::after {
    content: '';
    bottom: 0;
    left: 0;
    height: 45%;
    width: 100%;
    position: absolute;
    display: block;
    z-index: 30;

    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(255, 255, 255) 50%
    );
  }

  @media only screen and (max-width: $bp-largest) {
    flex-basis: 35%;
  }

  @media only screen and (max-width: $bp-medium-low) {
    display: none;
  }

  &-picture {
    height: 80%;
    width: auto;
  }

  &-links {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 3rem;
    &--list {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    cursor: pointer;
    transition: all 0.3s;

    &_download {
      @media only screen and (max-width: $bp-download-btn-transfer) {
        display: none;
      }
    }

    &-icon {
      position: relative;
      z-index: 3;
      height: 2.3rem;
      width: auto;

      &_download {
        height: 2.8rem;
      }
    }
  }
}

//roudn button icons
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  color: $light;
  background-color: $dark;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  height: 4.5rem;
  width: 4.5rem;
  border: 2px solid $dark;

  border-radius: 50%;
  transition: color 0.1s;

  &_download {
    background: linear-gradient(
      to right,
      var(--color-primary),
      80%,
      var(--color-primary-light)
    );
    border: 2px solid $dark-download;

    &:hover {
      color: $dark-download !important;
    }
  }

  .inner {
    position: absolute;
    display: block;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $light;
    transition: all 0.2s ease;
  }

  &:hover {
    color: $dark;

    .inner {
      height: 110%;
      width: 110%;
    }
  }
}
