@import '../../sass/variables.scss';

.button-round {
  list-style: none;
  text-align: center;
  z-index: 2;

  &_container {
    position: relative;
    display: flex;
    justify-content: flex-start;

    &:before {
      content: '';
      display: block;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #fff;
      width: 100%;
      height: 2px;
      position: absolute;
      top: 50%;
      z-index: -1;
    }
  }

  &_content:link,
  &_content:visited {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    background-color: #f7f7f7;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7f7f7),
      to(#e7e7e7)
    );
    color: #a7a7a7;
    margin: 0 3rem;
    width: 7rem;
    height: 7rem;

    position: relative;
    text-align: center;

    border-radius: 50%;
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    border: solid 1px transparent;

    &:before {
      content: '';
      display: block;
      background: #fff;
      border-top: 2px solid #ddd;
      position: absolute;
      top: -1.8rem;
      left: -1.8rem;
      bottom: -1.8rem;
      right: -1.8rem;
      z-index: -1;
      border-radius: 50%;
      box-shadow: inset 0px 8px 48px #ddd;
    }

    &:hover {
      text-decoration: none;
      color: var(--color-primary);
      background: #f5f5f5;
    }

    &:active {
      box-shadow: 0px 3px 4px #aaa inset, 0px 2px 3px #fff;
    }
  }

  &_text {
    font-size: 1.4rem;
    font-weight: 700;
  }
  &_icon {
    height: 3.5rem;
    width: 3.5rem;
  }
}
