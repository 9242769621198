@import '../../sass/variables.scss';

.wrapper {
  font-size: 7rem;
  width: 100%;
  position: absolute;
  bottom: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: exclusion;
  z-index: 35;
  flex-wrap: wrap;

  @media only screen and (min-width: $bp-name-largest) {
    font-size: 8rem;
  }

  @media only screen and (max-width: $bp-name-large) {
    font-size: 6rem;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 5rem;
  }

  &.invert {
    span {
      color: black;

      &::before {
        -webkit-text-stroke: 0.1em var(--color);
      }
    }
  }

  .name,
  .surname {
    // --color: #4e2101;
    --color: #0f284f;
    font-family: 'Impact', 'ICA Rubrik', 'Xball', 'Arial Narrow Bold',
      sans-serif;
    font-weight: 400;
    font-style: italic;
    display: block;
    position: absolute;
    bottom: 50%;
    color: var(--color);
    letter-spacing: -0.005em;

    &::before,
    &::after {
      content: attr(data-text);
      display: block;
      position: relative;
      padding: 0 0.1em;
    }

    &::before {
      position: absolute;
      -webkit-text-stroke: 0.1em black;
      z-index: 0;
    }

    &:first-child {
      transform: translate(-0.955em, -0.345em);

      @media only screen and (min-width: $bp-small) and (max-width: $bp-name-medium) {
        transform: translate(-0.45em, -0.65em);
      }
    }

    &:last-child {
      // --color: #0f284f;
      --color: #4e2101;
      transform: translate(0.955em, 0.25em);

      @media only screen and (min-width: $bp-small) and (max-width: $bp-name-medium) {
        transform: translate(0.45em, 0em);
      }
    }
  }
}
